<template>
  <component :is="tag" :class="['content', { [`content--${size}`]: size }]">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Container',
  props: {
    size: {
      validator(value) {
        return ['full', 'big'].includes(value);
      },
    },
    tag: {
      type: String,
      default: 'section',
    },
  },
};
</script>

<style lang="scss">
.content {
  width: 100%;
  max-width: rem(1168px);
  padding-right: calc($grid-gutter-width / 2);
  padding-left: calc($grid-gutter-width / 2);
  margin-right: auto;
  margin-left: auto;
}

.content--full {
  max-width: 100%;
}

.content--big {
  max-width: rem(1268px);
}
</style>
