<template>
  <div class="perimeter-question margins__double">
    <ValidationObserver v-slot="{ handleSubmit }" slim>
      <form
        @submit.prevent="handleSubmit(onSubmit)"
        autocomplete="off"
        novalidate
      >
        <template v-for="decision in mappedDecisions">
          <b-row v-if="decision.key === 'perimeterLength'" :key="decision.key">
            <b-col md="7">
              <b-row>
                <b-col sm="6">
                  <ValidationProvider
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <SpinButton
                      :id="decision.key"
                      :name="decision.key"
                      :min="getMinValue(decision)"
                      :max="getMaxValue(decision)"
                      :label="`${$mt(decision.label)}:`"
                      v-model="formData[decision.key]"
                      :error="errors[0]"
                      :suffix="
                        decision.unit ? $t(`UNITS.${decision.unit}.LONG`) : null
                      "
                    />
                  </ValidationProvider>
                </b-col>
                <b-col sm="6" class="margins__mobile-sm hidden-mobile">
                  <p class="spin-button__label">
                    {{ $t('GET_LENGTH_FROM_MAP_LABEL') }}
                  </p>
                  <Button @click="showModal" variant="inverse" block>
                    {{ $t('GET_LENGTH_FROM_MAP') }}
                    <template v-slot:icon-right>
                      <MapIconSVG class="icon" />
                    </template>
                  </Button>
                </b-col>
                <b-col
                  cols="12"
                  v-if="$mt(decision.help) || $mt(decision.description)"
                  class="margins__one"
                >
                  <Info
                    :title="$mt(decision.description)"
                    :text="$mt(decision.help)"
                  />
                </b-col>
              </b-row>

              <Separator size="medium" hasLine />
            </b-col>
          </b-row>

          <b-row v-if="decision.key === 'numberOfThreads'" :key="decision.key">
            <b-col md="7">
              <b-row align-v="end">
                <b-col sm="6">
                  <ValidationProvider
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <SpinButton
                      :id="decision.key"
                      :name="decision.key"
                      :min="getMinValue(decision)"
                      :max="getMaxValue(decision)"
                      :label="`${$mt(decision.label)}:`"
                      v-model="formData[decision.key]"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </b-col>
                <b-col
                  sm="6"
                  class="margins__mobile-sm perimeter-question__threads"
                >
                  <Threads :count="formData[decision.key]" />
                </b-col>
                <b-col
                  cols="12"
                  v-if="$mt(decision.help) || $mt(decision.description)"
                  class="margins__one"
                >
                  <Info
                    :title="$mt(decision.description)"
                    :text="$mt(decision.help)"
                  />
                </b-col>
              </b-row>

              <Separator size="medium" hasLine />
            </b-col>
          </b-row>

          <b-row v-if="Array.isArray(decision)" :key="JSON.stringify(decision)">
            <b-col sm="6" md="7">
              <b-row>
                <b-col
                  md="6"
                  v-for="(decision, index) in decision"
                  :key="index"
                  :class="{
                    margins__triple: index > (window.innerWidth > 800 ? 1 : 0),
                  }"
                >
                  <ValidationProvider
                    v-if="decision.valueType === 'TEXT'"
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <Input
                      type="number"
                      :min="getMinValue(decision)"
                      :max="getMaxValue(decision)"
                      :step="getValidation(decision).step"
                      :name="decision.key"
                      :label="`${$mt(decision.label)}:`"
                      v-model.trim="formData[decision.key]"
                      :suffix="
                        decision.unit
                          ? $t(`UNITS.${decision.unit}.SHORT`)
                          : null
                      "
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                  <ValidationProvider
                    v-if="decision.valueType === 'DECIMAL'"
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <SpinButton
                      :id="decision.key"
                      :name="decision.key"
                      :min="getMinValue(decision)"
                      :max="getMaxValue(decision)"
                      :label="`${$mt(decision.label)}:`"
                      v-model="formData[decision.key]"
                      :error="errors[0]"
                    />
                  </ValidationProvider>

                  <Info
                    v-if="$mt(decision.help) || $mt(decision.description)"
                    :title="$mt(decision.description)"
                    :text="$mt(decision.help)"
                    class="margins__one"
                  />
                </b-col>
              </b-row>

              <Separator size="medium" hasLine />
            </b-col>
            <b-col sm="6" md="5" class="margins__mobile-sm">
              <Card :title="$t('PERIMETER_QUESTION_FENCE_LEGEND')">
                <b-row>
                  <b-col cols="6">
                    <FenceSchemeSVG />
                  </b-col>
                  <b-col cols="6">
                    <FenceSchemeLegendNoSVG v-if="LOCALE === 'no'" />
                    <FenceSchemeLegendSvSVG v-if="LOCALE === 'sv'" />
                  </b-col>
                </b-row>
              </Card>
            </b-col>
          </b-row>
        </template>

        <Separator size="small" />

        <Button type="submit">
          {{ $t('GO_TO_NEXT') }}
        </Button>
      </form>
    </ValidationObserver>

    <b-modal ref="mapModalRef" size="md" centered hide-footer hide-header>
      <PerimeterModalContent
        @change="formData.perimeterLength = $event"
        @hideModal="hideModal"
      />
    </b-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FenceSchemeSVG from '@/assets/images/fence-scheme.svg?inline';
import FenceSchemeLegendNoSVG from '@/assets/images/fence-scheme-legend-no.svg?inline';
import FenceSchemeLegendSvSVG from '@/assets/images/fence-scheme-legend-sv.svg?inline';
import MapIconSVG from '@/assets/images/icon-map.svg?inline';
import Button from '@/components/Button';
import Input from '@/components/Input';
import SpinButton from '@/components/SpinButton';
import PerimeterModalContent from '@/components/PerimeterQuestion/PerimeterModalContent';
import Separator from '@/components/Separator';
import Threads from '@/components/PerimeterQuestion/Threads';
import { getValidation } from '@/utils/validation';
import Card from '@/components/Card.vue';
import Info from '@/components/Info.vue';
import { LOCALE } from '@/constants';

export default {
  name: 'PerimeterQuestion',
  components: {
    FenceSchemeSVG,
    FenceSchemeLegendNoSVG,
    FenceSchemeLegendSvSVG,
    Button,
    Input,
    SpinButton,
    PerimeterModalContent,
    Threads,
    MapIconSVG,
    Separator,
    Card,
    Info,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  mounted() {
    this.question.decisions.forEach((decision) => {
      const previouslyAnswered =
        this.answers[decision.key] || this.allAnswers[decision.key];

      const value =
        previouslyAnswered ||
        (decision.valueType === 'DECIMAL' ? this.getDefaultValue(decision) : 0);

      this.formData = {
        ...this.formData,
        [decision.key]: value,
      };
    });
  },
  methods: {
    getDefaultValue(decision) {
      const validation = decision.validations.find(
        (validation) => !isNaN(validation.min.value),
      );

      return validation
        ? validation.min.including
          ? validation.min.value
          : validation.min.value + 1
        : 0;
    },
    onSubmit() {
      this.$emit('change', this.formData);
    },
    showModal() {
      this.$refs.mapModalRef.show();
    },
    hideModal() {
      this.$refs.mapModalRef.hide();
    },
    getValidation(decision) {
      return getValidation(decision);
    },
    getMinValue(decision) {
      const minValue = decision.validations.find(
        (validation) => validation.min,
      );

      if (minValue) {
        return minValue.min.including
          ? minValue.min.value
          : minValue.min.value + 1;
      }

      return 0;
    },
    getMaxValue(decision) {
      const maxValue = decision.validations.find(
        (validation) => validation.max,
      );

      if (maxValue) {
        return maxValue.max.including
          ? maxValue.max.value
          : maxValue.max.value + 1;
      }

      return undefined;
    },
  },
  computed: {
    ...mapState(['answers', 'allAnswers']),
    mappedDecisions() {
      if (this.question.key === 'perimeter') {
        return [
          ...this.question.decisions.slice(0, 2),
          [...this.question.decisions.slice(2)],
        ];
      } else if (this.question.key === 'netPerimeter') {
        return [
          ...this.question.decisions.slice(0, 1),
          [...this.question.decisions.slice(1)],
        ];
      }
      return this.question.decisions;
    },
    LOCALE() {
      return LOCALE;
    },
  },
};
</script>

<style lang="scss">
.perimeter-question__threads {
  text-align: center;

  @include min-width(sm) {
    text-align: left;
  }
}
</style>
