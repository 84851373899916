<template>
  <div class="answers">
    <Button @click="answersOpen = !answersOpen" variant="link">
      <BarsSVG class="icon icon--small" />
    </Button>

    <portal to="answers-outlet">
      <aside
        :class="['answers__wrapper', { 'answers__wrapper--open': answersOpen }]"
      >
        <div class="answers__header">
          <h4>{{ $t('SUMMARY') }}</h4>

          <Button @click="answersOpen = !answersOpen" variant="link">
            <CloseSVG class="icon" />
          </Button>
        </div>

        <div class="answers__content">
          <ul class="answers__list">
            <li
              v-for="question in answeredQuestions"
              :key="question.id"
              class="answers__list-item"
            >
              <div class="answers__question-icon">
                <CheckInverseSVG class="icon icon--small" />
              </div>
              <div class="answers__question-content">
                <h5 class="text-primary">
                  {{ $mt(question.title) }}
                </h5>
                <ul>
                  <li v-for="answer in getAnswers(question)" :key="answer.key">
                    <p>
                      {{ $mt(answer.label) }}
                      <span v-if="answer.answer">: {{ answer.answer }}</span>
                    </p>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </aside>
    </portal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CheckInverseSVG from '@/assets/images/icon-check-circle-inverse.svg?inline';
import BarsSVG from '@/assets/images/icon-bars.svg?inline';
import CloseSVG from '@/assets/images/icon-close.svg?inline';
import { getAnswers } from '@/utils/store';
import Button from '@/components/Button';

export default {
  name: 'Answers',
  components: {
    Button,
    BarsSVG,
    CloseSVG,
    CheckInverseSVG,
  },
  data() {
    return {
      answersOpen: false,
    };
  },
  methods: {
    getAnswers(question) {
      return getAnswers(question, this.answers);
    },
  },
  computed: {
    ...mapState(['answers', 'answeredQuestions']),
  },
};
</script>

<style lang="scss">
.answers {
  display: flex;
}

.answers__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 1rem;
  box-shadow: $elevation-medium;
}

.answers__wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: $color-white;
  z-index: 100;
  width: 20rem;
  height: 100%;
  transition: transform 0.5s;
  transform: translateX(20rem);
  box-shadow: $elevation-medium;
}

.answers__wrapper--open {
  transform: translateX(0);
}

.answers__content {
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100% - 46px - 1rem);
  padding-bottom: 2rem;
}

.answers__list-item {
  display: flex;
  margin-top: 0.25rem;
}

.answers__question-icon {
  display: flex;
  align-items: center;
  background: $answers-color-icon-background;
  padding: 0.25rem;
}

.answers__question-content {
  border-bottom: 2px solid $answers-color-border-bottom;
  padding: 0.5rem 1rem;
  width: 100%;

  > * + * {
    margin-top: 0.5rem;
  }
}
</style>
