<template>
  <main>
    <transition name="slide-fade">
      <Start v-if="showStartView" />
    </transition>

    <Dialogue v-if="!showStartView" />
  </main>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Start from '@/containers/Start.vue';
import Dialogue from '@/containers/Dialogue.vue';

export default {
  name: 'Calculator',
  components: {
    Start,
    Dialogue,
  },
  mounted() {
    if (this.answeredQuestions.length === 0) {
      this.startDialogue();
    }
  },
  computed: {
    ...mapState(['answeredQuestions']),
    ...mapGetters(['questions']),
    showStartView() {
      return this.answeredQuestions.length === 0;
    },
  },
  methods: {
    ...mapActions(['startDialogue']),
  },
};
</script>

<style lang="scss">
@media screen and (prefers-reduced-motion: no-preference) {
  .slide-fade-leave-active {
    transition: opacity 1s, transform 1.2s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-leave-to {
    opacity: 0;
  }
}
</style>
