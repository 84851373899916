<template>
  <div id="app">
    <h1 class="sr-only">{{ $t('PAGE_TITLE') }}</h1>
    <Header />

    <router-view />

    <portal-target name="answers-outlet"></portal-target>
  </div>
</template>

<script>
import Header from '@/components/Header';

export default {
  name: 'App',
  components: {
    Header,
  },
};
</script>
