<template>
  <div class="dialogue">
    <Margins>
      <Hero>
        <h2>{{ $t('PAGE_TITLE') }}</h2>
      </Hero>
      <Progress />

      <section>
        <h2 class="sr-only">{{ $t('QUESTIONS') }}</h2>

        <transition-group name="questions" tag="div">
          <Question
            v-for="(question, index) in questions"
            :number="index + 1"
            :question="question"
            :key="question.id"
            :isAnswered="!!answers[question.key] || !!allAnswers[question.key]"
          />
        </transition-group>

        <div id="js-complete">
          <Container tag="div" v-if="complete" class="margins__triple">
            <Margins>
              <Card background="green-light" padding="small" shadow="none">
                <h2>
                  <CheckCircleInverseSVG class="icon text-primary" />
                  {{ $t('ALL_ANSWERED') }}
                </h2>
              </Card>

              <div class="text-center">
                <Button to="/offer/material-list">
                  <template v-slot:icon-right>
                    <ArrowRightSVG class="icon button__icon-animate" />
                  </template>
                  {{ $t('GET_OFFER') }}
                </Button>
              </div>
            </Margins>
          </Container>
        </div>
      </section>
    </Margins>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Hero from '@/components/Hero';
import Margins from '@/components/Margins';
import Question from '@/containers/Question.vue';
import Progress from '@/containers/Progress.vue';
import Container from '@/components/Container';
import Button from '@/components/Button';
import Card from '@/components/Card.vue';
import ArrowRightSVG from '@/assets/images/icon-long-arrow-alt-right-solid.svg?inline';
import CheckCircleInverseSVG from '@/assets/images/icon-check-circle-inverse.svg?inline';
import { scrollTo } from '@/utils';

export default {
  name: 'Dialogue',
  components: {
    Hero,
    Margins,
    Container,
    Button,
    Question,
    Progress,
    Card,
    ArrowRightSVG,
    CheckCircleInverseSVG,
  },
  watch: {
    complete(val) {
      this.$nextTick(() => {
        if (val) {
          scrollTo('#js-complete');
        }
      });
    },
  },
  computed: {
    ...mapState(['complete', 'answers', 'allAnswers']),
    ...mapGetters(['questions']),
  },
};
</script>

<style lang="scss">
.dialogue {
  min-height: calc(100vh - #{$main-padding-bottom});
}

@media screen and (prefers-reduced-motion: no-preference) {
  .questions-enter-active {
    transition: transform 0.5s, opacity 1s;
  }

  .questions-enter {
    opacity: 0;
    transform: translateY(30px);
  }
}
</style>
