<template>
  <div
    :class="[
      'card',
      {
        [`card--shadow-${shadow}`]: shadow,
        [`card--background-${background}`]: background,
        [`card--padding-${padding}`]: padding,
      },
    ]"
  >
    <div v-if="title" class="card__header">{{ title }}</div>
    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    title: String,
    shadow: {
      type: String,
      validator: function (value) {
        return ['none', 'small', 'medium'].includes(value);
      },
      default: 'small',
    },
    background: {
      type: String,
      validator: function (value) {
        return ['green-light'].includes(value);
      },
    },
    padding: {
      type: String,
      validator: function (value) {
        return ['none', 'small'].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.card {
  box-shadow: $elevation-small;
}

.card--shadow-none {
  box-shadow: none;
}

.card--shadow-medium {
  box-shadow: $elevation-medium;
}

.card__header {
  background: $color-grey-light;
  font-weight: $font-weight-bold;
  padding: 0.5rem 1rem;

  @include min-width(sm) {
    padding: 1rem 2rem;
  }
}

.card__content {
  background: $color-white;
  padding: 1rem;

  @include min-width(sm) {
    padding: 2rem;
  }

  .card--background-green-light & {
    background: $color-green-light;
  }

  .card--padding-none & {
    padding: 0;
  }

  .card--padding-small & {
    @include min-width(sm) {
      padding: 1rem;
    }
  }
}
</style>
