<template>
  <div
    class="google-map"
    :class="isMeasuring && 'is-measuring'"
    ref="mapParentRef"
  >
    <GmapMap
      ref="mapRef"
      :center="center"
      :zoom="zoom"
      :options="{ streetViewControl: false }"
      class="map"
    />
    <button type="button" class="map__button-measure" @click="toggleMeasuring">
      <template v-if="isMeasuring">{{
        $t('GET_MY_LOCATION_CLEAR_MEASURE')
      }}</template>
      <template v-else>{{ $t('GET_MY_LOCATION_START_MEASURE') }}</template>
    </button>
  </div>
</template>

<script>
import MeasureTool from 'measuretool-googlemaps-v3';

export default {
  name: 'GoogleMap',
  props: {
    currentLocation: Object,
  },
  data() {
    return {
      measureTool: null,
      isMeasuring: false,
      center: { lat: 61.7529118, lng: 15.1709969 },
      zoom: 6,
    };
  },
  watch: {
    currentLocation(newLocation) {
      this.center = newLocation;
      this.zoom = 16;
    },
  },
  async mounted() {
    const map = await this.$refs.mapRef.$mapPromise;

    this.measureTool = new MeasureTool(map);

    this.measureTool.addListener('measure_change', this.emitMeasure);
  },
  beforeUnmount() {
    this.measureTool.removeListener('measure_change', this.emitMeasure);
  },
  methods: {
    emitMeasure(event) {
      this.$emit('measure', event.result.length);
    },
    toggleMeasuring() {
      if (this.isMeasuring) {
        this.measureTool.end();
      } else {
        this.measureTool.start();
      }
      this.isMeasuring = !this.isMeasuring;
    },
  },
};
</script>

<style lang="scss">
$color-google-maps-button-background-hover: #ebebeb;
$color-google-maps-button-color: #565656;

.google-map {
  position: relative;
}

.google-map.is-measuring .measure-tool-svg-overlay {
  cursor: crosshair;
}

.map {
  height: 30rem;
  max-height: 50vh;
  width: 100%;
}

.map__button-measure {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  background: $color-white;
  border: 0;
  border-radius: 2px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  color: $color-google-maps-button-color;
  font-family: Roboto, Arial, sans-serif;
  font-size: $font-size-button;
  padding: 0 1.0625rem;
  height: 2.5rem;
  vertical-align: middle;
  cursor: pointer;
}

.map__button-measure:hover {
  background: $color-google-maps-button-background-hover;
  color: $color-black;
}

.google-map.is-measuring .map__button-measure {
  font-weight: 500;
  color: $color-black;
}
</style>
