<template>
  <component :is="tag" class="margins">
    <slot />
  </component>
</template>

<script>
export default {
  name: 'Margins',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
  },
};
</script>

<style lang="scss">
.margins > * + * {
  margin-top: 1rem;
}

.margins__half {
  margin-top: 0.5rem;
}

.margins__one {
  margin-top: 1rem;
}

.margins__double {
  margin-top: 2rem;
}

.margins__triple {
  margin-top: 3rem;
}

.margins__mobile-sm {
  margin-top: 1rem;

  @include min-width(sm) {
    margin-top: 0;
  }
}

.margins__mobile-md {
  margin-top: 1rem;

  @include min-width(md) {
    margin-top: 0;
  }
}

.margins__mobile-lg {
  margin-top: 1rem;

  @include min-width(lg) {
    margin-top: 0;
  }
}

.margins__mobile-md-double {
  margin-top: 2rem;

  @include min-width(md) {
    margin-top: 0;
  }
}
</style>
