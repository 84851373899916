import Vue from 'vue';
import 'focus-visible';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import { sync } from 'vuex-router-sync';
import * as VueGoogleMaps from 'vue2-google-maps';
import {
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate';
import PortalVue from 'portal-vue';
import VueGtag from 'vue-gtag';

import MapTranslatePlugin from './plugins/MapTranslatePlugin';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './i18n';
import './utils/filters';
import './utils/validation';

const norwegianCSS = () => import('./assets/scss/no/no-main.scss');
const swedishCSS = () => import('./assets/scss/sv/sv-main.scss');

switch (process.env.VUE_APP_I18N_LOCALE) {
  case 'no': {
    norwegianCSS();
    break;
  }
  case 'sv': {
    swedishCSS();
    break;
  }
}

sync(store, router);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(MapTranslatePlugin, { lang: i18n.locale });
Vue.use(PortalVue);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
setInteractionMode('eager');

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAP_ID,
    libraries: 'geometry',
  },
});

Vue.use(
  VueGtag,
  {
    config: {
      id: process.env.VUE_APP_GOOGLE_GTAG_ID,
      enabled: !!process.env.VUE_APP_GOOGLE_GTAG_ID,
    },
  },
  router,
);

Vue.prototype.window = window; // This is needed for using window object inside <template>

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
