<template>
  <div class="start">
    <div class="start__text">
      <Container>
        <Margins>
          <h2>
            {{ $t('START_QUESTION') }}
          </h2>

          <p class="start__description margins__triple">
            {{ $t('START_DESCRIPTION_1') }}
            <br />
            {{ $t('START_DESCRIPTION_2') }}
          </p>
        </Margins>
      </Container>
    </div>
    <div class="start__question">
      <Container>
        <Margins>
          <h3 class="h5">{{ $t('FIRST_QUESTION') }}</h3>

          <Options
            :decision="decision"
            class="margins__triple"
            @change="assignVariable"
          />

          <Info
            v-if="$mt(question.description) || $mt(question.help)"
            :title="$mt(question.description)"
            :text="$mt(question.help)"
            :open="$t('OPEN')"
            :close="$t('CLOSE')"
          />
        </Margins>
      </Container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Margins from '@/components/Margins';
import Options from '@/components/Options';
import Container from '@/components/Container';
import Info from '@/components/Info';

export default {
  name: 'Start',
  components: {
    Margins,
    Options,
    Container,
    Info,
  },
  data() {
    return {
      questionVariables: {},
      error: false,
    };
  },
  methods: {
    ...mapActions(['evaluateAnswer']),
    assignVariable(key, value) {
      this.questionVariables[key] = value;
      this.error = false;
      this.handleNext();
    },
    handleNext() {
      if (Object.keys(this.questionVariables).length === 0) {
        this.error = true;
        return false;
      }

      return this.evaluateAnswer({
        answerVariables: this.questionVariables,
        question: this.question,
      });
    },
  },
  computed: {
    ...mapGetters(['questions']),
    question() {
      return this.questions?.[0] || {};
    },
    decision() {
      return this.question.decisions?.[0] || {};
    },
  },
};
</script>

<style lang="scss">
.start__text {
  position: relative;
  background: $color-secondary;
  padding-top: 7vh;
  padding-bottom: 7vh;
}

.start__description {
  font-size: $h5-size;
}

.start__question {
  padding-top: 7vh;
}
</style>
