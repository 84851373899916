var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('article',{class:[
    'question',
    {
      'question--grey': _vm.number % 2 === 0,
    } ],style:(_vm.isAnswered && ("--arrow-position: " + _vm.arrowPosition + "px")),attrs:{"id":_vm.question.key}},[_c('Container',{attrs:{"tag":"div"}},[_c('Margins',[_c('h2',{staticClass:"h5 question__title"},[_c('span',[_vm._v(_vm._s(_vm.number))]),_c('span',[_vm._v(_vm._s(_vm.$mt(_vm.question.title)))])]),_c('Margins',{class:[
          'question__content',
          {
            'question__content--reverse': [
              'perimeter',
              'netPerimeter',
              'fencerPerimeter',
              'undergroundCable' ].includes(_vm.question.key),
          } ]},[_c('Decider',{attrs:{"question":_vm.question},on:{"change":_vm.assignVariable}}),(
            (_vm.$mt(_vm.question.description) || _vm.$mt(_vm.question.help)) &&
            _vm.question.decisions[0].options.length !== 1
          )?_c('Info',{attrs:{"title":_vm.$mt(_vm.question.description),"text":_vm.$mt(_vm.question.help),"open":_vm.$t('OPEN'),"close":_vm.$t('CLOSE')}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }