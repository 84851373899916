<template>
  <div class="spin-button">
    <label :for="name" class="spin-button__label">{{ label }}</label>
    <div class="spin-button__content">
      <div class="spin-button__spinbutton">
        <b-button class="spin-button__button" @click="changeInput(value - 1)">
          <b-icon icon="dash" class="spin-button__button-icon" />
        </b-button>

        <input
          :id="name"
          :value="value"
          :name="name"
          type="number"
          :min="min"
          :max="max"
          class="spin-button__input"
          @change="changeInput"
          @input="updateInput"
          :aria-invalid="error"
          :aria-describedby="`error-${id}`"
        />

        <b-button class="spin-button__button" @click="changeInput(value + 1)">
          <b-icon icon="plus" class="spin-button__button-icon" />
        </b-button>
      </div>
      <span v-if="suffix" class="spin-button__suffix" v-html="suffix" />
    </div>
    <p v-if="error" :id="`error-${id}`" class="spin-button__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'SpinButton',
  props: {
    id: String,
    name: {
      type: String,
      required: true,
    },
    value: [Number, String],
    label: String,
    min: Number,
    max: Number,
    error: String,
    suffix: String,
  },
  methods: {
    updateInput(event) {
      const value = isNaN(event) ? Number(event.target.value) : event;

      this.$emit('input', value < 0 ? 0 : value);
    },
    changeInput(event) {
      const value = isNaN(event) ? Number(event.target.value) : event;

      this.$emit('change', value < 0 ? 0 : value);
      this.$emit('input', value < 0 ? 0 : value);
    },
  },
};
</script>

<style lang="scss">
.spin-button__label {
  display: block;
  font-weight: $font-weight-bold;
  margin-bottom: 0.5rem;
  text-align: center;

  @include min-width(sm) {
    text-align: left;
  }
}

.spin-button__content {
  display: flex;
  align-items: center;
  justify-content: center;

  @include min-width(sm) {
    justify-content: flex-start;
  }
}

.spin-button__spinbutton {
  display: flex;

  > * + * {
    margin-left: 0.5rem;
  }
}

.spin-button__button {
  display: inline-block;
  background: $color-white;
  border: 1px solid transparent;
  box-shadow: $elevation-small;
  color: $color-primary;
  font-size: $h4-size;
  font-weight: $font-weight-bold;
  padding: 0.25rem !important;
  margin-top: 0.3125rem;
  height: 2.75rem;
  width: 2.75rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  transition: transform 0.25s;

  &:hover {
    border-color: $color-grey-light;
    box-shadow: none;
  }

  &:active {
    transform: scale(0.95);
  }
}

.spin-button__button-icon {
  vertical-align: text-bottom;
  font-size: 2rem;
}

.spin-button__input {
  align-items: center !important;
  text-align: center;
  background-image: none;
  background: $color-white;
  border: rem(1px) solid $color-black;
  font-size: $font-size-base-rem;
  font-weight: $font-weight-bold;
  line-height: 1;
  padding: 1.15625rem 0;
  height: 3.375rem;
  width: 3.375rem;
  text-align: center;

  &:focus {
    outline: none;
  }
}

.spin-button__suffix {
  font-weight: $font-weight-bold;
  margin-left: 0.5rem;
}

.spin-button__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
  text-align: center;

  @include min-width(sm) {
    text-align: left;
  }
}

.spin-button__input::-webkit-outer-spin-button,
.spin-button__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.spin-button__input[type='number'] {
  -moz-appearance: textfield;
}
</style>
