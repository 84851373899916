<template>
  <article
    :class="[
      'question',
      {
        'question--grey': number % 2 === 0,
      },
    ]"
    :id="question.key"
    :style="isAnswered && `--arrow-position: ${arrowPosition}px`"
  >
    <Container tag="div">
      <Margins>
        <h2 class="h5 question__title">
          <span>{{ number }}</span>
          <span>{{ $mt(question.title) }}</span>
        </h2>

        <Margins
          :class="[
            'question__content',
            {
              'question__content--reverse': [
                'perimeter',
                'netPerimeter',
                'fencerPerimeter',
                'undergroundCable',
              ].includes(question.key),
            },
          ]"
        >
          <Decider :question="question" @change="assignVariable" />

          <Info
            v-if="
              ($mt(question.description) || $mt(question.help)) &&
              question.decisions[0].options.length !== 1
            "
            :title="$mt(question.description)"
            :text="$mt(question.help)"
            :open="$t('OPEN')"
            :close="$t('CLOSE')"
          />
        </Margins>
      </Margins>
    </Container>
  </article>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Decider from '@/containers/Decider';
import { scrollTo } from '@/utils';
import Margins from '@/components/Margins';
import Container from '../components/Container.vue';
import Info from '../components/Info.vue';

export default {
  name: 'Question',
  components: {
    Decider,
    Margins,
    Container,
    Info,
  },
  props: {
    question: Object,
    number: Number,
    isAnswered: Boolean,
  },
  beforeMount() {
    window.addEventListener('resize', this.calculateArrowPosition);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.calculateArrowPosition);
  },
  watch: {
    isAnswered() {
      this.calculateArrowPosition();
    },
  },
  mounted() {
    scrollTo(`#${this.question.key}`);
    this.calculateArrowPosition();
  },
  data() {
    return {
      questionVariables: {},
      arrowPosition: 0,
    };
  },
  methods: {
    ...mapActions(['evaluateAnswer']),
    assignVariable(key, value) {
      if (typeof key === 'object') {
        for (const [variable, value] of Object.entries(key)) {
          this.questionVariables[variable] = value;
        }
      } else {
        this.questionVariables[key] = value;
      }
      this.handleNext();
    },
    handleNext() {
      this.evaluateAnswer({
        answerVariables: this.questionVariables,
        question: this.question,
      });
    },
    calculateArrowPosition() {
      if (!this.isAnswered) {
        return false;
      }

      const id = `js-${this.question.key}-${
        this.answers[this.question.key] || this.allAnswers[this.question.key]
      }`;
      const arrowLength = 36;
      const element = document.querySelector(`#${id}`);
      const { width, left } = element.getBoundingClientRect();

      this.arrowPosition = left + width / 2 - arrowLength / 2;
    },
  },
  computed: {
    ...mapState(['answers', 'allAnswers']),
  },
};
</script>

<style lang="scss">
.question {
  position: relative;
  padding-top: 2rem;
  padding-bottom: 2rem;
  box-shadow: $elevation-small;
  z-index: 1;
}

.question--grey {
  background: $color-grey-light;
  box-shadow: none;
  z-index: 0;
}

.question__title > * + * {
  margin-left: 0.625rem;
}

.question::after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  bottom: -1.125rem;
  left: 0;
  border: 1.125rem solid transparent;
  border-color: transparent transparent $color-white $color-white;
  transform: translateX(var(--arrow-position, -200%)) rotate(-45deg);
  box-shadow: -2px 2px 2px 0 rgba($color-black, 0.13);
  will-change: transform;
}

.question--grey::after {
  border-color: transparent transparent $color-grey-light $color-grey-light;
  box-shadow: none;
}

.question__content {
  display: flex;
  flex-direction: column;
}

.question__content--reverse {
  flex-direction: column-reverse;

  > * {
    margin-bottom: 1rem;
  }
}
</style>
