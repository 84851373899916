<template>
  <div
    :class="[
      'info',
      {
        'info--clickable': !!text,
        [`info--variant-${variant}`]: !!variant,
      },
    ]"
  >
    <component
      :is="text ? 'button' : 'div'"
      :type="text ? 'button' : null"
      @click="text ? (infoOpen = !infoOpen) : null"
      class="info__button"
    >
      <span class="info__button-text">
        <span class="info__icon-wrapper">
          <InfoSVG v-if="['info'].includes(variant)" class="icon info__icon" />
          <InfoCircleSVG
            v-if="['warning', 'notification'].includes(variant)"
            class="icon info__icon"
          />
          <ErrorSVG
            v-if="['error'].includes(variant)"
            class="icon info__icon"
          />
        </span>
        <span class="info__text" v-html="title"></span>
      </span>

      <span v-if="text" class="info__button-text">
        <span v-if="open && !infoOpen" class="info__open-label">{{
          open
        }}</span>
        <span v-if="close && infoOpen" class="info__close-label">{{
          close
        }}</span>
        <CloseSVG v-if="infoOpen" class="icon info__close" />
        <ChevronDownSVG v-if="!infoOpen" class="icon info__close" />
      </span>
    </component>

    <b-collapse v-if="text" v-model="infoOpen" class="info__collapse">
      <Margins v-html="text" class="info__collapse-content" />
    </b-collapse>
  </div>
</template>

<script>
import InfoSVG from '@/assets/images/icon-info.svg?inline';
import InfoCircleSVG from '@/assets/images/icon-info-circle.svg?inline';
import CloseSVG from '@/assets/images/icon-close.svg?inline';
import ChevronDownSVG from '@/assets/images/icon-chevron-down-solid.svg?inline';
import ErrorSVG from '@/assets/images/icon-error.svg?inline';
import Margins from './Margins.vue';

export default {
  name: 'Info',
  components: {
    InfoSVG,
    Margins,
    CloseSVG,
    ChevronDownSVG,
    InfoCircleSVG,
    ErrorSVG,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
    },
    open: {
      type: String,
    },
    close: {
      type: String,
    },
    variant: {
      type: String,
      validator(value) {
        return ['info', 'warning', 'notification', 'error'].includes(value);
      },
      default: 'info',
    },
  },
  data() {
    return {
      infoOpen: false,
    };
  },
};
</script>

<style lang="scss">
.info {
  background: $color-white;
  border: 1px solid $color-grey-light;
}

.info--variant-warning {
  border-color: $color-warning;
}

.info--variant-notification {
  border-color: $color-notification;
}

.info--variant-error {
  border-color: $color-error;
}

.info--clickable:hover {
  box-shadow: $elevation-small;
}

.info__button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 0.2rem;
  text-align: left;
  letter-spacing: $letter-spacing-base;
  width: 100%;
}

button.info__button {
  background-image: none;
  border: none;
  line-height: $line-height-base;
  cursor: pointer;
}

.info__button-text {
  display: flex;
}

.info__icon-wrapper {
  display: flex;
  margin-right: 0.25rem;
}

.info--variant-warning .info__icon-wrapper {
  background: $color-warning;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-left: -0.2rem;
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;
}

.info--variant-notification .info__icon-wrapper {
  background: $color-notification;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-left: -0.2rem;
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;
}

.info--variant-error .info__icon-wrapper {
  background: $color-error;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-left: -0.2rem;
  margin-top: -0.2rem;
  margin-bottom: -0.2rem;
}

.info--variant-info .info__icon {
  font-size: 2em;
  color: $color-info;
}

.info--variant-warning .info__icon {
  align-self: center;
  font-size: 1.5em;
}

.info--variant-notification .info__icon {
  align-self: center;
  font-size: 1.5em;
}

.info--variant-error .info__icon {
  align-self: center;
  font-size: 1.5em;
}

.info__text {
  align-self: center;
}

.info__collapse {
  padding: 0.2rem;
}

.info__collapse-content {
  background: $color-white;
  padding: 0.5rem 1rem 1rem 2.25rem;
}

.info__close {
  color: $color-primary;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.info__open-label,
.info__close-label {
  color: $color-primary;
  white-space: nowrap;

  .info--clickable:hover & {
    text-decoration: underline;
  }
}

.info__open-label {
  opacity: 0;
  transition: opacity 0.3s;

  .info--clickable:hover & {
    opacity: 1;
  }
}
</style>
