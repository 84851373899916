<template>
  <div class="threads">
    <Fence2PostsSVG class="threads__icon-fence-2-posts" />

    <div class="threads__icons-wrapper">
      <FenceThreadSVG
        class="threads__icon-fence-thread"
        v-for="n in count"
        :key="n"
      />
    </div>
  </div>
</template>

<script>
import Fence2PostsSVG from '@/assets/images/fence-2-posts.svg?inline';
import FenceThreadSVG from '@/assets/images/fence-thread.svg?inline';

export default {
  name: 'Threads',
  components: {
    Fence2PostsSVG,
    FenceThreadSVG,
  },
  props: {
    count: Number,
  },
};
</script>

<style lang="scss">
.threads {
  position: relative;
  display: inline-block;
}

.threads__icons-wrapper {
  position: absolute;
  top: 0;
  left: -0.625rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  height: 3.375rem;
}

.threads__icon-fence-2-posts {
  width: 4.4375rem;
}

.threads__icon-fence-thread {
  width: 5.6875rem;
}
</style>
