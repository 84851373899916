export const getAnswers = (question, answers) => {
  return question['decisions']
    .map((decision) => {
      if (decision.inputType === 'SELECT') {
        return decision.options
          .filter((option) => answers[decision.key] === option.value)
          .map((option) => {
            return {
              value: option.value,
              label: option.text,
              unit: decision.unit,
            };
          });
      }

      const answerKey = Object.keys(answers).find(
        (key) => key === decision.key,
      );

      if (answerKey) {
        return {
          label: decision.label,
          unit: decision.unit,
          value: answers[answerKey],
          answer: answers[decision.key].toString(),
        };
      }
    })
    .flat();
};
