<template>
  <Margins class="decider">
    <template
      v-if="
        ['perimeter', 'netPerimeter', 'fencerPerimeter'].includes(question.key)
      "
    >
      <PerimeterQuestion
        :question="question"
        @change="(key, value) => $emit('change', key, value)"
      />
    </template>
    <template v-else>
      <ValidationObserver v-slot="{ handleSubmit }" slim>
        <form @submit.prevent="handleSubmit(onSubmit)">
          <div
            v-for="(decision, index) in question.decisions"
            :key="decision.key"
          >
            <Options
              v-if="decision.inputType === 'SELECT'"
              :decision="decision"
              @change="(key, value) => $emit('change', key, value)"
            />

            <template v-if="decision.inputType === 'INPUT'">
              <b-row v-if="decision.valueType === 'TEXT'">
                <b-col md="6">
                  <ValidationProvider
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <Input
                      :name="decision.key"
                      :label="`${$mt(decision.label)}:`"
                      v-model.trim="formData[decision.key]"
                      :class="{ margins__double: index !== 0 }"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row v-if="decision.valueType === 'DECIMAL'">
                <b-col md="3">
                  <ValidationProvider
                    :rules="getValidation(decision).rules"
                    v-slot="{ errors }"
                    slim
                  >
                    <Input
                      type="number"
                      :min="0"
                      :step="getValidation(decision).step"
                      :name="decision.key"
                      :label="`${$mt(decision.label)}:`"
                      v-model.trim="formData[decision.key]"
                      :suffix="
                        decision.unit
                          ? $t(`UNITS.${decision.unit}.SHORT`)
                          : null
                      "
                      :class="{ margins__double: index !== 0 }"
                      :error="errors[0]"
                    />
                  </ValidationProvider>
                </b-col>
              </b-row>
            </template>

            <Info
              v-if="decision.options.length === 1"
              :title="
                $t('ONE_OPTION_QUESTION_INFO', [
                  chosenAnimalInCurrentLocale,
                  decision.options[0].text[$i18n.locale],
                ])
              "
              variant="notification"
            />
          </div>

          <div v-if="useButtonForNextQuestion">
            <Separator size="small" />
            <Button type="submit" :disabled="isNextButtonDisabled">
              {{ $t('GO_TO_NEXT') }}
            </Button>
          </div>
        </form>
      </ValidationObserver>
    </template>
  </Margins>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Options from '@/components/Options';
import Input from '@/components/Input';
import Margins from '@/components/Margins';
import Button from '@/components/Button';
import PerimeterQuestion from './PerimeterQuestion';
import { getValidation } from '@/utils/validation';
import Separator from '@/components/Separator';
import Info from '@/components/Info';

export default {
  name: 'Decider',
  components: {
    Options,
    Input,
    Margins,
    Button,
    PerimeterQuestion,
    Separator,
    Info,
  },
  props: {
    question: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
    };
  },
  mounted() {
    this.question.decisions.forEach((decision) => {
      let value = this.answers[decision.key] || this.allAnswers[decision.key];

      // If the previous answer is not present in new options, delete the answer
      const decisionValues = decision?.options?.map(
        (decision) => decision.value,
      );
      if (!decisionValues?.includes(value)) {
        value = undefined;
      }
      const hasOnlyOneOption = decision.options.length === 1;

      if (!value) {
        value = hasOnlyOneOption
          ? // When has one option, use the first option as default value and move on to next question
            decision.options[0].value
          : decision.valueType === 'DECIMAL'
          ? this.getDefaultValue(decision)
          : '';
      }

      this.formData = {
        ...this.formData,
        [decision.key]: value,
      };
    });
    const isQuestionFound = this.isQuestionFound();
    const isAnswerFound = this.isAnswerFound();
    const hasOnlyOneOption = this.question.decisions.every(
      (decision) => decision.options.length === 1,
    );

    if (
      (isQuestionFound && isAnswerFound && !this.complete) ||
      hasOnlyOneOption
    ) {
      this.onSubmit();
    }
  },
  methods: {
    getDefaultValue(decision) {
      const validation = decision.validations.find(
        (validation) => !isNaN(validation.min.value),
      );

      return validation
        ? validation.min.including
          ? validation.min.value
          : validation.min.value + 1
        : 0;
    },
    isQuestionFound() {
      return [
        ...Object.keys(this.answers),
        ...Object.keys(this.allAnswers),
      ].includes(this.question.key);
    },
    isAnswerFound() {
      const questionOptions = this.question.decisions[0]?.options.map(
        (option) => option.value,
      );
      const answeredOptions = [
        ...Object.values(this.answers),
        ...Object.values(this.allAnswers),
      ];

      return (
        questionOptions &&
        answeredOptions.some((answerOption) =>
          questionOptions.includes(answerOption),
        )
      );
    },
    onSubmit() {
      this.$emit('change', this.formData);
    },
    getValidation(decision) {
      return getValidation(decision);
    },
  },
  computed: {
    ...mapState(['answers', 'allAnswers', 'complete']),
    ...mapGetters(['questions']),
    useButtonForNextQuestion() {
      return this.question.decisions.some(
        (decision) => decision.inputType === 'INPUT',
      );
    },
    isNextButtonDisabled() {
      return (
        Object.keys(this.formData).length !== this.question.decisions.length
      );
    },
    chosenAnimalInCurrentLocale() {
      const animalKey = this.answers['animalType'];
      return this.questions
        .filter((question) => question.key === 'animalType')
        ?.map((question) =>
          question.decisions[0]?.options
            ?.filter((option) => option.value === animalKey)
            ?.map((option) => option.text[this.$i18n.locale]),
        );
    },
  },
};
</script>
