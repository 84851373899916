var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Margins',{staticClass:"decider"},[(
      ['perimeter', 'netPerimeter', 'fencerPerimeter'].includes(_vm.question.key)
    )?[_c('PerimeterQuestion',{attrs:{"question":_vm.question},on:{"change":function (key, value) { return _vm.$emit('change', key, value); }}})]:[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._l((_vm.question.decisions),function(decision,index){return _c('div',{key:decision.key},[(decision.inputType === 'SELECT')?_c('Options',{attrs:{"decision":decision},on:{"change":function (key, value) { return _vm.$emit('change', key, value); }}}):_vm._e(),(decision.inputType === 'INPUT')?[(decision.valueType === 'TEXT')?_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('Input',{class:{ margins__double: index !== 0 },attrs:{"name":decision.key,"label":((_vm.$mt(decision.label)) + ":"),"error":errors[0]},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[decision.key]"}})]}}],null,true)})],1)],1):_vm._e(),(decision.valueType === 'DECIMAL')?_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var errors = ref.errors;
return [_c('Input',{class:{ margins__double: index !== 0 },attrs:{"type":"number","min":0,"step":_vm.getValidation(decision).step,"name":decision.key,"label":((_vm.$mt(decision.label)) + ":"),"suffix":decision.unit
                        ? _vm.$t(("UNITS." + (decision.unit) + ".SHORT"))
                        : null,"error":errors[0]},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[decision.key]"}})]}}],null,true)})],1)],1):_vm._e()]:_vm._e(),(decision.options.length === 1)?_c('Info',{attrs:{"title":_vm.$t('ONE_OPTION_QUESTION_INFO', [
                _vm.chosenAnimalInCurrentLocale,
                decision.options[0].text[_vm.$i18n.locale] ]),"variant":"notification"}}):_vm._e()],2)}),(_vm.useButtonForNextQuestion)?_c('div',[_c('Separator',{attrs:{"size":"small"}}),_c('Button',{attrs:{"type":"submit","disabled":_vm.isNextButtonDisabled}},[_vm._v(" "+_vm._s(_vm.$t('GO_TO_NEXT'))+" ")])],1):_vm._e()],2)]}}])})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }