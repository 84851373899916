<template>
  <section class="hero">
    <Container tag="div">
      <slot />
    </Container>
  </section>
</template>

<script>
import Container from '@/components/Container';

export default {
  name: 'Hero',
  components: {
    Container,
  },
};
</script>

<style lang="scss">
.hero {
  background: $color-secondary;
  padding-top: 2.5rem;
  padding-bottom: 4.375rem;
}
</style>
