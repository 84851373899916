import axios from 'axios';

export default {
  async start() {
    let response = await axios.put('/api/dialogue', {});
    return response.data;
  },

  async evaluate(variables) {
    let response = await axios.put('/api/dialogue', variables);
    return response.data;
  },

  async downloadPdf(data) {
    const response = await axios({
      url: '/api/offer/pdf',
      method: 'POST',
      data: data,
      responseType: 'blob',
    });
    return response.data;
  },
};
