import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'universal-cookie';
import { setHtmlElementLanguage, setLanguageCookie } from './utils';
import { LOCALE } from '@/constants';

Vue.use(VueI18n);

const cookies = new Cookies();

function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i,
  );

  let localesToInclude = [LOCALE, fallbackLocale];
  setLanguageCookie(cookies, locale);

  const messages = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    const isIncluded = localesToInclude.find((loc) => key.includes(loc));
    if (matched && matched.length > 1 && isIncluded) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const locale = cookies.get('locale') || process.env.VUE_APP_I18N_LOCALE || 'en';
const fallbackLocale = process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en';

setHtmlElementLanguage(locale);

export default new VueI18n({
  locale,
  fallbackLocale,
  messages: loadLocaleMessages(),
});
