<template>
  <header class="header">
    <Container tag="div" class="header__container">
      <a href="/" @click="backToStart" class="header__link">
        <img
          :src="settings.logo"
          :alt="settings.title"
          loading="lazy"
          class="header__logo"
        />
      </a>

      <Answers v-if="Object.keys(answers).length > 0" />
    </Container>
  </header>
</template>

<script>
import { mapMutations, mapActions, mapState } from 'vuex';
import Container from '@/components/Container';
import GrannGardenLogo from '@/assets/images/granngarden-logo.svg';
import FelleskjopetLogo from '@/assets/images/felleskjopet-logo.svg';
import { LOCALE } from '@/constants';
import Answers from '@/containers/Answers';

const settings = {
  no: {
    title: 'Felleskjopet',
    logo: FelleskjopetLogo,
  },
  sv: {
    title: 'Granngarden',
    logo: GrannGardenLogo,
  },
};

export default {
  name: 'Header',
  components: { Container, Answers },
  methods: {
    ...mapMutations(['resetInstance']),
    ...mapActions(['startDialogue']),
    backToStart(e) {
      e.preventDefault();

      this.resetInstance();
      this.startDialogue();
      this.$router.push('/');
    },
  },
  computed: {
    ...mapState(['answers']),
    settings() {
      return settings[LOCALE];
    },
  },
};
</script>

<style lang="scss">
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  box-shadow: $elevation-small;
  background: $color-white;
  padding-top: 2rem;
  padding-bottom: 2rem;
  height: 6.5rem;
  z-index: 10;
}

.header__container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  body.is-scrolled & {
    transform: translateY(-1.25rem);
  }
}

.header__link {
  display: inline-block;
  transition: transform 0.3s;
}

.header__logo {
  height: 2rem;
}
</style>
