import { configure, extend } from 'vee-validate';
import { max_value, min_value, required } from 'vee-validate/dist/rules';

import i18n from '../i18n';
import { getPrecision } from '../utils';

configure({
  defaultMessage: (_, values) => {
    return i18n.t(`VALIDATION.${values._rule_.toUpperCase()}`, values);
  },
});

extend('required', {
  ...required,
});

extend('min_value', {
  ...min_value,
  message: (_, { min }) => i18n.t('VALIDATION.MIN_VALUE', [min]),
});

extend('max_value', {
  ...max_value,
  message: (_, { max }) => i18n.t('VALIDATION.MAX_VALUE', [max]),
});

extend('numeric', (value) => {
  return !isNaN(+value);
});

extend('fixed', {
  validate(value, { scale }) {
    return +scale === getPrecision(value);
  },
  params: ['scale'],
  message: (_, { scale }) => {
    return i18n.t('VALIDATION.FIXED', [scale]);
  },
});

export function getValidation(decision) {
  let rules = 'required';
  let step = null;

  decision.validations.forEach((validation) => {
    switch (validation.type) {
      case 'DECIMAL_SCALE':
        const { fixed, scale } = validation;

        if (fixed) {
          rules += `|fixed:${scale}`;

          step = scale === 0 ? '1' : `0.${'1'.padStart(scale, 0)}`;
        }

        if (!rules.includes('numeric')) {
          rules += '|numeric';
        }

        break;
      case 'NUMERIC_RANGE':
        const { min, max } = validation;

        if (min) {
          const value = min.including ? min.value : min.value + 1;

          rules += `|min_value:${value}`;
        }

        if (max) {
          const value = max.including ? max.value : max.value - 1;

          rules += `|max_value:${value}`;
        }

        if (!rules.includes('numeric')) {
          rules += '|numeric';
        }

        break;
      default:
    }
  });

  return { rules, step };
}
