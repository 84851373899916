<template>
  <Margins class="perimeter-modal-content">
    <div class="perimeter-modal-content__header">
      <div class="perimeter-modal-content__header-content">
        <div class="perimeter-modal-content__header-icon">
          <MapIconSVG class="icon icon--medium" />
        </div>
        <h5>{{ $t('PERIMETER_QUESTION_TITLE') }}</h5>
      </div>
      <Button variant="link" @click="hideModal" :aria-label="$t('CLOSE')">
        <CloseSVG class="icon" />
      </Button>
    </div>

    <p>{{ infoText }}</p>

    <b-row align-h="center" class="margins__double">
      <b-col cols="auto">
        <Button
          v-if="!!window.navigator.geolocation"
          @click="getCurrentLocation"
          :loading="loadingLocation"
          :variant="hasCurrentLocation ? 'inverse' : null"
        >
          <template v-slot:icon-right>
            <CheckCircleSVG v-if="hasCurrentLocation" class="icon" />
            <MapIconSVG v-else class="icon" />
          </template>
          {{
            loadingLocation
              ? $t('GET_MY_LOCATION_FROM_MAP_LOADING')
              : $t('GET_MY_LOCATION_FROM_MAP')
          }}
        </Button>
      </b-col>
    </b-row>

    <GoogleMap
      :currentLocation="currentLocation"
      @measure="perimeterLength = $event"
      class="margins__double perimeter-modal-content__map"
    />

    <Card
      background="green-light"
      padding="small"
      shadow="none"
      class="text-center"
    >
      {{ $t('FENCE_LENGTH') }}:
      <strong>{{ Math.round(perimeterLength) }}m</strong>
    </Card>

    <b-row align-v="center" align-h="center" class="margins__double">
      <b-col cols="auto">
        <Button variant="ghost" @click="hideModal">
          {{ $t('CANCEL') }}
        </Button>
      </b-col>
      <b-col cols="auto">
        <Button @click="useMapData">
          {{ $t('READY') }}
        </Button>
      </b-col>
    </b-row>
  </Margins>
</template>

<script>
import CloseSVG from '@/assets/images/icon-close.svg?inline';
import Button from '@/components/Button';
import Margins from '@/components/Margins';
import { isTouchDevice } from '@/utils';
import MapIconSVG from '@/assets/images/icon-map.svg?inline';
import CheckCircleSVG from '@/assets/images/icon-check-circle.svg?inline';
import Card from '@/components/Card.vue';
import GoogleMap from './GoogleMap';

export default {
  name: 'PerimeterModalContent',
  components: {
    Button,
    Margins,
    CloseSVG,
    MapIconSVG,
    Card,
    CheckCircleSVG,
    GoogleMap,
  },
  data() {
    return {
      loadingLocation: false,
      currentLocation: {},
      perimeterLength: 0,
    };
  },
  methods: {
    getCurrentLocation() {
      this.loadingLocation = true;
      window.navigator.geolocation.getCurrentPosition(this.handleLocation);
    },
    handleLocation({ coords: { latitude, longitude } }) {
      this.currentLocation = {
        lat: latitude,
        lng: longitude,
      };
      this.loadingLocation = false;
    },
    useMapData() {
      this.$emit('change', Math.round(this.perimeterLength).toString());
      this.hideModal();
    },
    hideModal() {
      this.$emit('hideModal');
    },
  },
  computed: {
    infoText() {
      return isTouchDevice()
        ? this.$t('TOUCH_DEVICE_HELP_TEXT')
        : this.$t('NOT_TOUCH_DEVICE_HELP_TEXT');
    },
    hasCurrentLocation() {
      return Object.keys(this.currentLocation).length > 0;
    },
  },
};
</script>

<style lang="scss">
.perimeter-modal-content__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $color-grey-light;
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding-right: 0.5rem;
}

.perimeter-modal-content__header-content {
  display: flex;
  align-items: center;

  > * + * {
    margin-left: 1rem;
  }
}

.perimeter-modal-content__header-icon {
  display: inline-flex;
  background: $color-grey-light;
  color: $color-orange;
  font-size: 0.75rem;
  line-height: 1;
  padding: 0.75rem;
}

.perimeter-modal-content__map {
  margin-left: -1rem;
  margin-right: -1rem;
}
</style>
