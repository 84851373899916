import Vue from 'vue';
import Vuex from 'vuex';

import DialogueApi from '@/api/DialogueApi';
import ShopApi from '@/api/ShopApi';
import offerStore from '@/store/offerStore';
import { isInStock } from '@/utils';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    offerStore,
  },
  state: {
    allAnswers: {},
    answers: {},
    answeredQuestions: [],
    answeredQuestionsV2: [],
    pendingQuestion: null,
    complete: false,
    isDownloading: false,
    isGetRedirectUrlLoading: false,
    isShopRedirectError: false,
    redirectUrl: null,
  },
  getters: {
    questions(state) {
      const questions = [...state.answeredQuestions];
      const alreadyAnswered = questions.find(
        (question) => question.id === state.pendingQuestion?.id,
      );

      if (state.pendingQuestion !== null && !alreadyAnswered) {
        questions.push({ ...state.pendingQuestion, next: true });
      }

      return questions;
    },
  },
  actions: {
    async startDialogue({ commit }) {
      commit('resetInstance');
      const dialogue = await DialogueApi.start();
      commit('setPendingQuestion', dialogue.pendingQuestion);
    },
    async evaluateAnswer({ commit, state }, { answerVariables, question }) {
      commit('setAnsweredQuestions', question);
      commit('setAllAnswers', answerVariables);
      commit('setAnswers', answerVariables);

      const dialogue = await DialogueApi.evaluate(state.answers);

      commit('setAnsweredQuestionsV2', dialogue.answeredQuestions);
      commit('setPendingQuestion', dialogue.pendingQuestion);
      commit('setComplete', dialogue.complete);

      if (dialogue.offer) {
        commit('offerStore/setOffer', dialogue.offer);
      }
    },

    async downloadPdf({ state, commit }) {
      commit('setDownloading', true);
      try {
        const categoryProducts = state.offerStore.productsPerCategory;
        Object.keys(categoryProducts).forEach((category) => {
          categoryProducts[category].offeredProducts.forEach(
            (offeredProduct) => {
              offeredProduct.quantity = offeredProduct.chosenQuantity;
              offeredProduct.availableProducts[
                offeredProduct.selectedProductIndex
              ].upsaleProducts.forEach(
                (accessory) => (accessory.quantity = accessory.chosenQuantity),
              );
            },
          );
        });
        const responseData = await DialogueApi.downloadPdf({
          productsPerCategory: categoryProducts,
          totalPrice: state.offerStore.totalPrice,
          answeredQuestions: state.answeredQuestionsV2,
        });
        const url = window.URL.createObjectURL(new Blob([responseData]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'offer.pdf');
        document.body.appendChild(link);
        link.click();
      } catch (error) {
        console.error(error);
      } finally {
        commit('setDownloading', false);
      }
    },
    async getRedirectUrl({ commit, rootGetters }) {
      commit('setToShopLoading', true);
      commit('setShopRedirectError', false);
      const products = {
        FenceProducts: rootGetters['offerStore/getSelectedProducts']
          .filter((product) => isInStock(product, product.packsNeeded))
          .map((product) => ({
            productNumber: product.artNo,
            quantity: product.packsNeeded,
          })),
      };
      try {
        const response = await ShopApi.proceedToShop(products);
        commit('setRedirectUrl', response.redirectUrl);
      } catch (err) {
        commit('setShopRedirectError', true);
      } finally {
        commit('setToShopLoading', false);
      }
    },
  },
  mutations: {
    setAllAnswers(state, newAnswers) {
      state.allAnswers = { ...state.allAnswers, ...newAnswers };
    },
    setAnswers(state, newAnswers) {
      let answers = { ...state.answers };
      const stateAnswersKeys = Object.keys(answers);
      const isAnswerChange = Object.keys(newAnswers).every((answer) =>
        stateAnswersKeys.includes(answer),
      );

      if (isAnswerChange) {
        const answeredAnswersKeys = state.answeredQuestions
          .map(({ decisions }) =>
            decisions
              .filter(({ key }) => stateAnswersKeys.includes(key))
              .map(({ key }) => key),
          )
          .flat();

        answers = Object.fromEntries(
          Object.entries(answers).filter(([key]) =>
            answeredAnswersKeys.includes(key),
          ),
        );
      }

      state.answers = { ...answers, ...newAnswers };
    },
    setAnsweredQuestions(state, question) {
      const alreadyAnsweredIndex = state.answeredQuestions.findIndex(
        (q) => q.id === question.id,
      );

      if (alreadyAnsweredIndex < 0) {
        if (state.pendingQuestion) {
          state.answeredQuestions = [
            ...state.answeredQuestions,
            state.pendingQuestion,
          ];
        }
      } else {
        state.answeredQuestions = state.answeredQuestions.splice(
          0,
          alreadyAnsweredIndex + 1,
        );
      }
    },
    setAnsweredQuestionsV2(state, question) {
      state.answeredQuestionsV2 = question;
    },
    setPendingQuestion(state, pendingQuestion) {
      state.pendingQuestion = pendingQuestion;
    },
    setComplete(state, complete) {
      state.complete = complete;
    },
    setDownloading(state, isDownloading) {
      state.isDownloading = isDownloading;
    },
    resetInstance(state) {
      state.allAnswers = {};
      state.answers = {};
      state.answeredQuestions = [];
      state.answeredQuestionsV2 = [];
      state.pendingQuestion = null;
      state.complete = false;

      state.offerStore.productsPerCategory = null;
      state.offerStore.totalPrice = null;
    },
    setToShopLoading(state, isLoading) {
      state.isGetRedirectUrlLoading = isLoading;
    },
    setShopRedirectError(state, isError) {
      state.isShopRedirectError = isError;
    },
    setRedirectUrl(state, newUrl) {
      state.redirectUrl = newUrl;
    },
  },
});
