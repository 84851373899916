import i18n from '../i18n';

export default {
  install: (Vue) => {
    Vue.prototype.$mt = (map) => {
      if (!!map) {
        return map[i18n.locale].length ? map[i18n.locale] : '';
      }

      return '';
    };
  },
};
