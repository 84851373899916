var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"perimeter-question margins__double"},[_c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"off","novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_vm._l((_vm.mappedDecisions),function(decision){return [(decision.key === 'perimeterLength')?_c('b-row',{key:decision.key},[_c('b-col',{attrs:{"md":"7"}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SpinButton',{attrs:{"id":decision.key,"name":decision.key,"min":_vm.getMinValue(decision),"max":_vm.getMaxValue(decision),"label":((_vm.$mt(decision.label)) + ":"),"error":errors[0],"suffix":decision.unit ? _vm.$t(("UNITS." + (decision.unit) + ".LONG")) : null},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, $$v)},expression:"formData[decision.key]"}})]}}],null,true)})],1),_c('b-col',{staticClass:"margins__mobile-sm hidden-mobile",attrs:{"sm":"6"}},[_c('p',{staticClass:"spin-button__label"},[_vm._v(" "+_vm._s(_vm.$t('GET_LENGTH_FROM_MAP_LABEL'))+" ")]),_c('Button',{attrs:{"variant":"inverse","block":""},on:{"click":_vm.showModal},scopedSlots:_vm._u([{key:"icon-right",fn:function(){return [_c('MapIconSVG',{staticClass:"icon"})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('GET_LENGTH_FROM_MAP'))+" ")])],1),(_vm.$mt(decision.help) || _vm.$mt(decision.description))?_c('b-col',{staticClass:"margins__one",attrs:{"cols":"12"}},[_c('Info',{attrs:{"title":_vm.$mt(decision.description),"text":_vm.$mt(decision.help)}})],1):_vm._e()],1),_c('Separator',{attrs:{"size":"medium","hasLine":""}})],1)],1):_vm._e(),(decision.key === 'numberOfThreads')?_c('b-row',{key:decision.key},[_c('b-col',{attrs:{"md":"7"}},[_c('b-row',{attrs:{"align-v":"end"}},[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('SpinButton',{attrs:{"id":decision.key,"name":decision.key,"min":_vm.getMinValue(decision),"max":_vm.getMaxValue(decision),"label":((_vm.$mt(decision.label)) + ":"),"error":errors[0]},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, $$v)},expression:"formData[decision.key]"}})]}}],null,true)})],1),_c('b-col',{staticClass:"margins__mobile-sm perimeter-question__threads",attrs:{"sm":"6"}},[_c('Threads',{attrs:{"count":_vm.formData[decision.key]}})],1),(_vm.$mt(decision.help) || _vm.$mt(decision.description))?_c('b-col',{staticClass:"margins__one",attrs:{"cols":"12"}},[_c('Info',{attrs:{"title":_vm.$mt(decision.description),"text":_vm.$mt(decision.help)}})],1):_vm._e()],1),_c('Separator',{attrs:{"size":"medium","hasLine":""}})],1)],1):_vm._e(),(Array.isArray(decision))?_c('b-row',{key:JSON.stringify(decision)},[_c('b-col',{attrs:{"sm":"6","md":"7"}},[_c('b-row',_vm._l((decision),function(decision,index){return _c('b-col',{key:index,class:{
                  margins__triple: index > (_vm.window.innerWidth > 800 ? 1 : 0),
                },attrs:{"md":"6"}},[(decision.valueType === 'TEXT')?_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('Input',{attrs:{"type":"number","min":_vm.getMinValue(decision),"max":_vm.getMaxValue(decision),"step":_vm.getValidation(decision).step,"name":decision.key,"label":((_vm.$mt(decision.label)) + ":"),"suffix":decision.unit
                        ? _vm.$t(("UNITS." + (decision.unit) + ".SHORT"))
                        : null,"error":errors[0]},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData[decision.key]"}})]}}],null,true)}):_vm._e(),(decision.valueType === 'DECIMAL')?_c('ValidationProvider',{attrs:{"rules":_vm.getValidation(decision).rules,"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var errors = ref.errors;
return [_c('SpinButton',{attrs:{"id":decision.key,"name":decision.key,"min":_vm.getMinValue(decision),"max":_vm.getMaxValue(decision),"label":((_vm.$mt(decision.label)) + ":"),"error":errors[0]},model:{value:(_vm.formData[decision.key]),callback:function ($$v) {_vm.$set(_vm.formData, decision.key, $$v)},expression:"formData[decision.key]"}})]}}],null,true)}):_vm._e(),(_vm.$mt(decision.help) || _vm.$mt(decision.description))?_c('Info',{staticClass:"margins__one",attrs:{"title":_vm.$mt(decision.description),"text":_vm.$mt(decision.help)}}):_vm._e()],1)}),1),_c('Separator',{attrs:{"size":"medium","hasLine":""}})],1),_c('b-col',{staticClass:"margins__mobile-sm",attrs:{"sm":"6","md":"5"}},[_c('Card',{attrs:{"title":_vm.$t('PERIMETER_QUESTION_FENCE_LEGEND')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('FenceSchemeSVG')],1),_c('b-col',{attrs:{"cols":"6"}},[(_vm.LOCALE === 'no')?_c('FenceSchemeLegendNoSVG'):_vm._e(),(_vm.LOCALE === 'sv')?_c('FenceSchemeLegendSvSVG'):_vm._e()],1)],1)],1)],1)],1):_vm._e()]}),_c('Separator',{attrs:{"size":"small"}}),_c('Button',{attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('GO_TO_NEXT'))+" ")])],2)]}}])}),_c('b-modal',{ref:"mapModalRef",attrs:{"size":"md","centered":"","hide-footer":"","hide-header":""}},[_c('PerimeterModalContent',{on:{"change":function($event){_vm.formData.perimeterLength = $event},"hideModal":_vm.hideModal}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }