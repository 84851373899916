<template>
  <div class="options">
    <ul class="options__list">
      <li
        v-for="option in decision.options"
        :key="option.key"
        class="options__list-item"
      >
        <Option
          :option="option"
          :name="decision.key"
          :id="`${decision.key}-${option.value}`"
          :checked="
            [answers[decision.key], allAnswers[decision.key]].includes(
              option.value,
            )
          "
          :hasImage="['orderType', 'animalType'].includes(decision.key)"
          @click="(e) => handleChange(e, decision)"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Option from '@/components/Option';

export default {
  name: 'Options',
  components: {
    Option,
  },
  props: {
    decision: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleChange(e, decision) {
      this.$emit('change', decision.key, e);
    },
  },
  computed: {
    ...mapState(['answers', 'allAnswers']),
  },
};
</script>

<style lang="scss">
.options__list {
  margin-left: -0.625rem;
  margin-right: -0.625rem;

  @include min-width(md) {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.options__list-item {
  padding: 0.625rem;
}
</style>
