<template>
  <aside class="progress">
    <Container>
      <ul class="progress__list">
        <li
          v-for="(question, index) in answeredQuestions"
          :key="question.id"
          class="progress__item"
        >
          <a
            :href="`#${question.key}`"
            @click="scrollTo"
            class="progress__link"
          >
            <p class="progress__title">
              <span class="progress__number">{{ index + 1 }}</span>
            </p>
          </a>
        </li>

        <li v-if="pendingQuestion" class="progress__item">
          <p class="progress__title progress__title--current">
            <span class="progress__number">{{
              answeredQuestions.length + 1
            }}</span>
          </p>
        </li>
      </ul>
    </Container>
  </aside>
</template>

<script>
import { mapState } from 'vuex';
import { scrollTo } from '@/utils';
import Container from '../components/Container.vue';

export default {
  name: 'Progress',
  components: { Container },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    document.body.classList.remove('is-scrolled');
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      if (window.scrollY > 130) {
        document.body.classList.add('is-scrolled');
      } else {
        document.body.classList.remove('is-scrolled');
      }
    },
    scrollTo(event) {
      event.preventDefault();

      return scrollTo(event.target.hash, 100);
    },
  },
  computed: {
    ...mapState(['answeredQuestions', 'pendingQuestion']),
  },
};
</script>

<style lang="scss">
.progress {
  position: sticky;
  top: 3.85rem;
  margin-top: -2.65rem;
  z-index: 11;
}

.progress__list {
  display: flex;
  margin-left: calc($grid-gutter-width / 2 * -1);
  margin-right: calc($grid-gutter-width / 2 * -1);

  @include min-width(sm) {
    margin-left: 0;
    margin-right: 0;
  }
}

.progress__item {
  max-width: 3rem;
  width: 100%;

  @include min-width(sm) {
    max-width: 7rem;
  }
}

.progress__link {
  display: block;

  &:hover {
    text-decoration: none;
  }

  > * {
    pointer-events: none;
  }
}

.progress__title {
  position: relative;
  background: $progress-color-background;
  color: $progress-color-title;
  font-weight: $font-weight-bold;
  padding-bottom: 0.625rem;
  text-align: center;

  @include min-width(sm) {
    font-size: 1.125rem;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 0.625rem;
    background: $progress-color-background-after;
  }
}

.progress__link:hover .progress__title,
.progress__title--current {
  background: $progress-color-background-current;
  color: $progress-color-title-current;

  &::after {
    background: $progress-color-background-current-after;
  }
}

.progress__number {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  line-height: 1;
}
</style>
