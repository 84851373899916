<template>
  <component
    :is="to ? 'router-link' : href ? 'a' : 'button'"
    :to="to"
    :href="href"
    :target="target"
    :rel="target === '_blank' ? 'noopener noreferrer' : null"
    :type="isLink ? null : type"
    :class="[
      'button',
      {
        [`button--${variant}`]: !!variant,
        [`button--${size}`]: !!size,
        [`button--color-${color}`]: !!color,
        [`button--border-${border}`]: !!border,
        ['button--block']: block,
        ['button--active']: active,
        ['button--disabled']: disabled,
      },
    ]"
    @click="handleClick"
    :disabled="isLink ? null : disabled || loading"
    :aria-disabled="disabled || loading"
  >
    <span class="button__content">
      <slot name="icon-left" />
      <span class="button__text">
        <slot />
      </span>
      <slot name="icon-right" />
      <LoadingSVG class="icon" v-if="loading" />
    </span>
  </component>
</template>

<script>
import LoadingSVG from '@/assets/images/loading.svg?inline';

export default {
  name: 'Button',
  components: {
    LoadingSVG,
  },
  props: {
    type: {
      validator(value) {
        return ['button', 'submit'].includes(value);
      },
      default: 'button',
    },
    disabled: Boolean,
    loading: Boolean,
    variant: {
      validator(value) {
        return ['default', 'link', 'inverse', 'ghost', 'text'].includes(value);
      },
      default: 'default',
    },
    size: {
      validator(value) {
        return ['small', 'large'].includes(value);
      },
    },
    block: Boolean,
    color: {
      validator(value) {
        return ['success', 'error'].includes(value);
      },
    },
    border: {
      validator(value) {
        return ['rounded-full'].includes(value);
      },
    },
    active: Boolean,
    href: String,
    target: String,
    to: String,
  },
  methods: {
    handleClick(event) {
      if (this.isLink && this.disabled) {
        event.preventDefault();
        return;
      }
      this.$emit('click', event);
    },
  },
  computed: {
    isLink() {
      return this.href || this.to;
    },
  },
};
</script>

<style lang="scss">
.button-group {
  display: flex;

  * + * {
    margin-left: 0.5rem;
  }
}

.button {
  display: inline-flex;
  text-align: left;
  align-items: center;
  justify-content: center;
  background: $color-primary;
  border: 1px solid $color-primary;
  padding: 0.625rem 1.5rem;
  color: $button-text-color;
  font-size: $font-size-button;
  font-family: $font-family-header;
  font-weight: $font-weight-bold;
  line-height: $line-height-base;
  cursor: pointer;
  transition: transform 0.25s;

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }

  > * {
    pointer-events: none;
  }

  &.button--disabled,
  &:disabled {
    opacity: 0.5;
    box-shadow: none;
    cursor: default;
  }
}

.button--link {
  background: transparent;
  border-color: transparent;
  color: $color-primary;
  font-size: $font-size-base-rem;
  padding: 0;

  &.button--active,
  &:hover {
    box-shadow: none;
  }
}

.button--inverse {
  background: $color-white;
  color: $color-primary;

  &:hover {
    background: $color-primary;
    color: $color-white;
    opacity: 1;
  }
}

.button--color-success {
  background: $color-success-dark;
  border-color: $color-success-dark;
}

.button--color-error {
  background: $color-error;
  border-color: $color-error;
}

.button--border-rounded-full {
  border-radius: 99em;
}

.button--ghost {
  background: transparent;
  border-color: transparent;
  border-radius: 99em;
  color: $color-primary;
  padding: rem(12px);

  &:hover {
    background: $color-primary-light;
    color: $color-black-light;
    opacity: 1;
  }

  .button--small & {
    padding: rem(8px);
  }

  .button--large & {
    padding: rem(20px);
  }
}

.button--text {
  background: transparent;
  border: none;
  color: $color-primary;
  font-weight: $font-weight;
  padding: 0.5rem;
  text-align: left;

  &.button--active,
  &:hover {
    color: $color-primary-dark;
    opacity: 1;

    .icon {
      color: $color-primary-dark;
    }
  }

  &.button--small {
    font-size: $font-size-small;
  }
}

.button--small {
  font-size: 1rem;
  padding-top: rem(8px);
  padding-bottom: rem(8px);
}

.button--large {
  font-size: 1.5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.button--block {
  display: block;
  width: 100%;
}

.button__content {
  display: flex;
  align-items: center;
  justify-content: center;

  .button--text & {
    justify-content: start;
  }
}

.button__text {
  display: flex;
}

.icon + .button__text,
.button__text + .icon {
  margin-left: 0.5rem;
}

.button__icon-animate {
  transition: transform 0.25s ease-in-out;

  .button:hover:not(:disabled):not(.button--disabled) & {
    transform: translateX(-6px);
  }

  .button:hover:not(:disabled):not(.button--disabled) .button__text + & {
    transform: translateX(6px);
  }
}
</style>
