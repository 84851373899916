export const scrollTo = (id, offset = 50) => {
  const yOffset = -offset;
  const element = document.querySelector(id);
  const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

  window.scrollTo({ top: y, behavior: 'smooth' });
};

export const isTouchDevice = () => {
  try {
    document.createEvent('TouchEvent');
    return true;
  } catch (e) {
    return false;
  }
};

export const setHtmlElementLanguage = (locale) => {
  document.documentElement.setAttribute('lang', locale);
};

export const setDocumentTitle = (title) => {
  document.title = title;
};

export const getPrecision = (number) => {
  const s = number + '';
  const d = s.indexOf('.') + 1;

  return !d ? 0 : s.length - d;
};

export const setLanguageCookie = (cookies, locale) => {
  cookies.set('locale', locale, {
    path: '/',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
  });
};

export const isInStock = (product, packsNeeded) => {
  if (!product?.stockLevel) {
    return false;
  }
  return packsNeeded <= product.stockLevel;
};
export const isDiscounted = (product) => {
  return (
    !!product?.priceWithDiscount &&
    product.pricePerPack > product?.priceWithDiscount
  );
};
