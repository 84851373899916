import Vue from 'vue';
import VueRouter from 'vue-router';

import Calculator from '../views/Calculator';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Calculator,
  },
  {
    path: '/offer',
    component: () =>
      import(/* webpackChunkName: "offer" */ '../views/Offer.vue'),
    children: [
      {
        path: 'material-list',
        name: 'Material List',
        component: () =>
          import(
            /* webpackChunkName: "offer-material" */ '../containers/MaterialList'
          ),
      },
      {
        path: 'purchase-list',
        name: 'Purchase List',
        component: () =>
          import(
            /* webpackChunkName: "offer-purchase" */ '../containers/PurchaseList'
          ),
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
