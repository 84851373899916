function mapQuantityToChosenQuantity(array) {
  return array.map((item) => ({
    ...item,
    chosenQuantity: item.quantity,
  }));
}

function mapOfferedProducts(offeredProducts) {
  return mapQuantityToChosenQuantity(offeredProducts).map((offeredProduct) => ({
    ...offeredProduct,
    availableProducts: offeredProduct.availableProducts.map((product) => ({
      ...product,
    })),
  }));
}

export default {
  namespaced: true,
  state: {
    productsPerCategory: null,
    totalPrice: null,
  },
  mutations: {
    setOffer(state, offer) {
      state.totalPrice = offer.totalPrice;
      state.productsPerCategory = Object.fromEntries(
        Object.entries(offer.productsPerCategory).map(
          ([category, products]) => [
            category,
            {
              ...products,
              categoryAccessoriesTotalPrice: 0,
              offeredProducts: mapOfferedProducts(products.offeredProducts),
            },
          ],
        ),
      );
    },
    setSelectedProduct(state, payload) {
      state.productsPerCategory[payload.category].offeredProducts[
        payload.offeredProductIndex
      ].selectedProductIndex = payload.selectedIndex;
    },
    adjustPrice(state, payload) {
      const targetCategory = state.productsPerCategory[payload.category];
      const targetOfferedProduct =
        targetCategory.offeredProducts[payload.offeredProductIndex];
      const oldProductPrice = targetOfferedProduct.price;

      targetCategory.categoryTotalPrice -= oldProductPrice;
      state.totalPrice -= oldProductPrice;

      targetOfferedProduct.price = payload.newPrice;
      targetOfferedProduct.chosenQuantity = payload.chosenQuantity;
      targetCategory.categoryTotalPrice += payload.newPrice;
      state.totalPrice += payload.newPrice;
    },
    adjustAccessoryPrice(state, payload) {
      const targetCategory = state.productsPerCategory[payload.category];
      const targetAccessory =
        targetCategory.offeredProducts[payload.offeredProductIndex]
          .availableProducts[payload.selectedIndex].upsaleProducts[
          payload.accessoryIndex
        ];
      const accessoryPrice = targetAccessory.price;

      if (accessoryPrice) {
        targetCategory.categoryAccessoriesTotalPrice -= accessoryPrice;
        state.totalPrice -= accessoryPrice;
      }

      targetAccessory.price = payload.newPrice;
      targetAccessory.active = payload.active;
      targetAccessory.chosenQuantity = payload.chosenQuantity;

      targetCategory.categoryAccessoriesTotalPrice += payload.newPrice;
      state.totalPrice += payload.newPrice;
    },
  },
  actions: {},
  getters: {
    getSelectedProducts: (state) => {
      const allProducts = [];

      Object.keys(state.productsPerCategory).forEach((category) => {
        state.productsPerCategory[category].offeredProducts.forEach(
          (offeredProduct) => {
            if (offeredProduct.price > 0) {
              const selectedProduct =
                offeredProduct.availableProducts[
                  offeredProduct.selectedProductIndex
                ];
              selectedProduct.price = offeredProduct.price;
              selectedProduct.chosenQuantity = offeredProduct.chosenQuantity;
              selectedProduct.packsNeeded = Math.ceil(
                selectedProduct.chosenQuantity / selectedProduct.unitsInPack,
              );

              allProducts.push(selectedProduct);

              if (selectedProduct.upsaleProducts) {
                selectedProduct.upsaleProducts.forEach((accessory) => {
                  if (accessory.price > 0) {
                    allProducts.push(accessory);
                  }
                });
              }
            }
          },
        );
      });

      return allProducts;
    },
  },
};
