<template>
  <div class="option" :id="`js-${id}`">
    <input
      type="radio"
      :id="id"
      :name="name"
      :checked="checked"
      :value="option.value"
      class="option__input"
      @input="updateInput"
      @change="changeInput"
      @click="handleClick"
    />
    <label :for="id" class="option__label">
      <figure v-if="hasImage" class="option__figure">
        <img
          :src="getImage(option.value)"
          :alt="option.values"
          class="option__icon"
        />
      </figure>
      <span class="option__text">{{ $mt(option.text) }}</span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Option',
  props: {
    option: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    checked: Boolean,
    hasImage: Boolean,
  },
  methods: {
    updateInput(e) {
      this.$emit('input', e.target.value);
    },
    changeInput(e) {
      this.$emit('change', e.target.value);
    },
    handleClick(e) {
      this.$emit('click', e.target.value);
    },
    getImage(value) {
      try {
        return require(`@/assets/images/answers/icon-${this.name}-${value}.svg`);
      } catch (error) {
        return '';
      }
    },
  },
};
</script>

<style lang="scss">
.option {
  position: relative;
  min-width: 10rem;
}

.option__input {
  position: absolute;
  top: auto;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  clip: rect(1px, 1px, 1px, 1px);
  width: 1px;
  height: 1px;
  white-space: nowrap;
}

.option__label {
  display: block;
  cursor: pointer;

  > * + * {
    margin-top: 1.5rem;
  }

  &:hover {
    opacity: 0.8;
  }
}

.option__icon {
  height: 2.5rem;
}

.option__text {
  display: block;
  background: $color-white;
  border: 1px solid $color-primary;
  color: $color-primary;
  font-size: $font-size-button;
  font-family: $font-family-header;
  font-weight: $font-weight-bold;
  padding: 0.625rem 1.5rem;
  text-align: center;

  .option__input:checked + .option__label & {
    background-color: $color-primary;
    color: $color-white;
  }
}
</style>
